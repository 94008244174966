//* hooks
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//* pages
// Auth
import JoinPage from "@pages/auth/JoinPage"; // 회원가입
import LoginPage from "@pages/auth/LoginPage"; // 로그인
import FindAccountPage from "@pages/auth/FindAccountPage"; // 아이디, 비밀번호 찾기

// Client
import MyInfoPage from "@pages/client/MyInfoPage"; // 내 정보
import ConsultationListPage from "@pages/client/ConsultationListPage"; // 나의 상담 리스트
import ConsultationDetailPage from "@pages/client/ConsultationDetailPage"; // 나의 상담 상세
import ConsultationApplicationPage from "@pages/client/ConsultationApplicationPage"; // 상담신청

// Counselor
import ConsultationReportPage from "@pages/counselor/ConsultationReportPage"; // 상담 리포트
import ConsultationHistoryPage from "@pages/counselor/ConsultationHistoryPage"; // 상담 이력
import NotificationKeywordPage from "@pages/counselor/NotificationKeywordPage"; // 알림 키워드
import CounselorConsultationDetailPage from "@pages/counselor/ConsultationDetailPage"; // 상담 일지 상세
import ConsultationReportDetailPage from "@pages/counselor/ConsultationReportDetailPage"; // 상담 리포트 상세
import AllConsultationDiaryListPage from "@pages/counselor/AllConsultationDiaryListPage"; // 상담일지 상담요청전체
import TodayConsultationDiaryListPage from "@pages/counselor/TodayConsultationDiaryListPage"; // 상담일지 오늘의상담

// Common
import OnlineConsultationPage from "@pages/OnlineConsultationPage"; // 공통 상담 화면

import CallScreen from "../screens/CallScreen";
import HomeScreen from "../screens/HomeScreen";

function RouteList() {
  const ProtectedRoute = ({ element, ...rest }) => {
    const token = localStorage.getItem("access_token");
    return token ? element : <Navigate to="/login" />;
  };

  return (
    <React.Fragment>
      <Routes>
        {/* 로그인 */}
        <Route path="/login" element={<LoginPage />} />

        {/* 회원가입 */}
        <Route path="/join" element={<JoinPage />} />

        {/* 아이디, 비밀번호 찾기 */}
        {["/fdID", "/fdPW"].map((path, index) => {
          return (
            <Route path={path} element={<FindAccountPage />} key={index} />
          );
        })}

        {/* 마이페이지 */}
        <Route
          path="/my-info"
          element={<ProtectedRoute element={<MyInfoPage />} />}
        />
        <Route
          path="/my-consultation"
          element={<ProtectedRoute element={<ConsultationListPage />} />}
        />
        <Route
          path="/my-consultation/:id"
          element={<ProtectedRoute element={<ConsultationDetailPage />} />}
        />

        {/* 상담신청 */}
        <Route
          path="/consultation-application"
          element={<ProtectedRoute element={<ConsultationApplicationPage />} />}
        />

        {/* 상담일지 */}
        <Route
          path="/today-consultation-diary"
          element={
            <ProtectedRoute element={<TodayConsultationDiaryListPage />} />
          }
        />
        <Route
          path="/all-consultation-diary"
          element={
            <ProtectedRoute element={<AllConsultationDiaryListPage />} />
          }
        />
        <Route
          path="/consultation-diary/:id"
          element={
            <ProtectedRoute element={<CounselorConsultationDetailPage />} />
          }
        />

        {/* 공통 상담 */}
        <Route
          path="/consultation/:id"
          element={<ProtectedRoute element={<OnlineConsultationPage />} />}
        />

        {/* 상담이력 */}
        <Route
          path="/consultation-history"
          element={<ProtectedRoute element={<ConsultationHistoryPage />} />}
        />

        {/* 상담 리포트 */}
        <Route
          path="/consultation-report"
          element={<ProtectedRoute element={<ConsultationReportPage />} />}
        />
        <Route
          path="/consultation-report/:id"
          element={
            <ProtectedRoute element={<ConsultationReportDetailPage />} />
          }
        />

        {/* 알림 키워드 */}
        <Route
          path="/notification-keyword"
          element={<ProtectedRoute element={<NotificationKeywordPage />} />}
        />

        {/* <Route path="/home" element={<HomeScreen />} />
        <Route path="/call/:username/:room" element={<CallScreen />} /> */}
      </Routes>
    </React.Fragment>
  );
}

export default RouteList;
