// 공통 header

//* hooks
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

//* api
import { api } from "@api";

function Header() {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const username = localStorage.getItem("user_name");
  const usertype = localStorage.getItem("user_type");

  const headerMenu = [
    { name: "상담신청", path: "/consultation-application" },
    { name: "상담일지", path: "/today-consultation-diary" },
    { name: "상담이력", path: "/consultation-history" },
    { name: "상담 리포트", path: "/consultation-report" },
    { name: "알림 키워드", path: "/notification-keyword" },
  ];

  const showAlert = (type) => {
    return window.alert(`${type}만 접근 가능합니다.`);
  };

  const navigateHandler = (path) => {
    const accessControl = {
      "/consultation-application": "내담자",
      "/today-consultation-diary": "상담사",
      "/consultation-history": "상담사",
      "/consultation-report": "상담사",
      "/notification-keyword": "상담사",
      내정보: "내담자",
    };

    if (accessControl[path]) {
      if (usertype !== accessControl[path]) {
        showAlert(accessControl[path] === "상담사" ? "상담사" : "내담자");
      } else {
        navigate(path === "내정보" ? "/my-consultation" : path);
      }
    } else {
      navigate(path);
    }
  };

  const logout = async () => {
    try {
      await api.GET_LOGOUT();

      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_type");
      navigate("/login");
    } catch (err) {
      console.log("로그아웃 err", err);
    }
  };

  return (
    <HeaderContainer>
      <h1>
        <span>정신건강 상담</span> <span>시스템</span>
      </h1>

      <ul>
        {headerMenu.map((menu) => (
          <li
            key={menu.name}
            onClick={() => navigateHandler(menu.path)}
            className={
              menu.path === path ||
              (path === "/all-consultation-diary" &&
                menu.path === "/today-consultation-diary")
                ? "active"
                : ""
            }
          >
            {menu.name}
          </li>
        ))}
      </ul>

      <ul>
        <li onClick={() => navigateHandler("내정보")}>{username}</li>
        <li onClick={logout}>로그아웃</li>
      </ul>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  height: 100px;
  padding: 0rem 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.secondary};

  h1 {
    display: flex;
    font-size: 1.4rem;
    align-items: center;
    flex-direction: column;
    color: ${({ theme }) => theme.color.primary};
  }

  ul {
    display: flex;

    li {
      cursor: pointer;
      font-weight: 700;
      margin: 0rem 2rem;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.fontColor["text-3"]};
    }

    .active {
      color: #f5a52a;
    }
  }

  > :nth-child(3) {
    li {
      font-size: 0.8rem;
      margin: 0rem 1rem;
    }
  }
`;

export default Header;
