import request from "./axios";

export const api = {
  //* AUTH
  // 로그인
  POST_LOGIN: (payload) => {
    return request.post("login", payload);
  },
  // 로그아웃
  GET_LOGOUT: (payload) => {
    return request.get("logout", payload);
  },
  // 아이디 찾기
  POST_FIND_ID: (payload) => {
    return request.post("findId", payload);
  },
  // 비밀번호 찾기
  POST_FIND_PW: (payload) => {
    return request.post("findPw", payload);
  },
  // 비밀번호 변경
  POST_CHANGE_PW: (payload) => {
    return request.post("changePw", payload);
  },
  // 회원가입
  POST_JOIN: (payload) => {
    return request.post("join", payload);
  },

  //* 내담자
  // 내 정보 요청
  GET_MY_INFO: () => {
    return request.get("myInfo");
  },
  // 내 정보 수정 요청
  POST_MY_INFO: (payload) => {
    return request.post("modify", payload);
  },
  // 상담신청
  POST_CONSULTATION_APPLICATION: (payload) => {
    return request.post("consultingApply", payload);
  },

  //* 내담자 & 상담자
  // 상담 가능 시간
  GET_CONSULTATION_HOUR: (queryString) => {
    return request.get(`cnsltTime?counsel_dt=${queryString}`);
  },
  // 상담 리스트
  POST_CONSULTATION_LIST: (payload) => {
    return request.post("consultingList", payload);
  },
  // 상담일지 상세
  GET_CONSULTATION_DETAIL_DATA: (queryString) => {
    return request.get(`consultingInfo?seq=${queryString}`);
  },

  //* 상담사
  // 상담 저장
  POST_SAVE_CONSULTATION: (payload) => {
    return request.post("consultingUpdate", payload);
  },
  // 상담 전체 영상 저장
  POST_ENTIRE_CONSULTATION_VIDEO: (payload) => {
    return request.post("video", payload);
  },
  // 상담 영상 받기
  GET_CONSULTATION_VIDEO: (queryString) => {
    return request.get(`videoPlay?seq=${queryString}`);
  },
  // 알림키워드 리스트 & 검색
  POST_NOTICE_KEYWORDS_LIST: (payload) => {
    return request.post("keywordSelect", payload);
  },
  // 알림키워드 등록
  POST_NOTICE_KEYWORDS: (payload) => {
    return request.post("keywordInsert", payload);
  },
  // 알림키워드 삭제
  DELETE_NOTICE_KEYWORDS: (payload) => {
    return request.remove("keywordDelete", payload);
  },
  // 온라인 위스퍼
  POST_WHISPER_PAIR: (payload) => {
    return request.post("whisper/paired", payload);
  },
  // 방문 위스퍼
  POST_WHISPER_SINGLE: (payload) => {
    return request.post("whisper/single", payload);
  },
  // 내담자 영상 위스퍼
  POST_CLIENT_WHISPER: (payload) => {
    return request.post("client/whisper", payload);
  },
  // 안면 인식
  POST_OPEN_CV: (payload) => {
    return request.post("image", payload);
  },
  // 상담 리포트 상세
  POST_CONSULTATION_REPORT: (payload) => {
    return request.post("report", payload);
  },

  test: () => {
    return "test~~~";
  },
};
