// 회원가입 정보 입력 form

//* hooks
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//* style
import { CommonButton, CommonRequiedMessage } from "@styles/common";

//* api
import { api } from "@api";

function JoinForm({ initialValues, myInfo }) {
  //* state
  const [sex, setSex] = useState("여");
  const [initialUserpass, setInitialUserpass] = useState("");
  const [isInitialValues, setIsInitialValues] = useState(false);

  useEffect(() => {
    setIsInitialValues(initialValues.userid);
    setInitialUserpass(initialValues.userpass);
    setSex(initialValues.sex ? initialValues.sex : "여");

    for (let value in initialValues) {
      setFieldValue(value, initialValues[value]);
    }
  }, [initialValues]);

  const navigate = useNavigate();

  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: {
        userid: "",
        username: "",
        userpass: "",
        userpassCheck: "",
        cellphone: "",
        email: "",
        year: "",
        month: "",
        date: "",
        usertype: "",
      },
      validationSchema: Yup.object().shape({
        userid: Yup.string().required("아이디를 입력해 주세요."),
        userpass: Yup.string().required("비밀번호를 입력해 주세요."),
        userpassCheck: Yup.string()
          .oneOf([Yup.ref("userpass"), null], "비밀번호가 일치하지 않습니다.")
          .required("비밀번호 확인을 입력해 주세요."),
        username: Yup.string().required("이름을 입력해 주세요."),
        date: Yup.string().required("생년월일을 입력해 주세요."),
        cellphone: Yup.string().required("휴대전화번호를 입력해 주세요."),
        email: Yup.string().required("이메일을 입력해 주세요."),
      }),
      onSubmit: async (values) => {
        const { year, month, date, userpassCheck, ...rest } = values;
        const birthday = year + month + date;

        let changePw = "";
        initialUserpass === values.userpass
          ? (changePw = "N")
          : (changePw = "Y");

        values = { ...rest, birthday, sex };

        let requestAPi = isInitialValues
          ? api.POST_MY_INFO({ ...values, changePw })
          : api.POST_JOIN(values);

        try {
          const response = await requestAPi;

          alert(response.message);

          if (response.status === 200) {
            myInfo();
          } else {
            navigate("/login");
          }
        } catch (err) {
          console.log("회원가입 err", err);

          if (err.response.status) {
            alert(err.response.data.message);
          }
        }
      },
    });

  return (
    <JoinFormContainer onSubmit={handleSubmit}>
      <div className="join-field">
        <label htmlFor="userid">
          아이디 <span className="required">*</span>
        </label>
        <input
          id="userid"
          type="text"
          name="userid"
          value={values.userid}
          onChange={handleChange}
          disabled={isInitialValues}
        />
      </div>
      {touched.userid && errors.userid && (
        <CommonRequiedMessage>{errors.userid}</CommonRequiedMessage>
      )}

      <div className="join-field">
        <label htmlFor="userpass">
          비밀번호 <span className="required">*</span>
        </label>
        <input
          id="userpass"
          name="userpass"
          type="password"
          value={values.userpass}
          onChange={handleChange}
        />
      </div>
      {touched.userpass && errors.userpass && (
        <CommonRequiedMessage>{errors.userpass}</CommonRequiedMessage>
      )}

      <div className="join-field">
        <label htmlFor="userpassCheck">
          비밀번호 확인 <span className="required">*</span>
        </label>
        <input
          type="password"
          id="userpassCheck"
          name="userpassCheck"
          onChange={handleChange}
          value={values.userpassCheck}
        />
      </div>
      {touched.userpassCheck && errors.userpassCheck && (
        <CommonRequiedMessage>{errors.userpassCheck}</CommonRequiedMessage>
      )}

      <div id="halfJoinField">
        <div className="join-field">
          <label htmlFor="username">
            이름 <span className="required">*</span>
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={values.username}
            onChange={handleChange}
            disabled={isInitialValues}
          />
          {touched.username && errors.username && (
            <CommonRequiedMessage style={{ marginTop: "1rem" }}>
              {errors.username}
            </CommonRequiedMessage>
          )}
        </div>

        <div className="join-field">
          <label htmlFor="sex">
            성별 <span className="required">*</span>
          </label>
          <select id="sex" value={sex} onChange={(e) => setSex(e.target.value)}>
            <option value="여">여성</option>
            <option value="남">남성</option>
          </select>
        </div>
      </div>

      <p style={{ marginBottom: "0.4rem" }}>
        생년월일 <span className="required">*</span>
      </p>
      <div id="birthInput">
        <input
          id="year"
          type="text"
          name="year"
          value={values.year}
          onChange={handleChange}
        />
        <p>년(4자리)</p>
        <input
          id="month"
          type="text"
          name="month"
          value={values.month}
          onChange={handleChange}
        />
        <p>월</p>
        <input
          id="date"
          type="text"
          name="date"
          value={values.date}
          onChange={handleChange}
        />
        <p>일</p>
      </div>
      {((touched.year && errors.year) ||
        (touched.month && errors.month) ||
        (touched.date && errors.date)) && (
        <CommonRequiedMessage>{errors.date}</CommonRequiedMessage>
      )}

      <div className="join-field">
        <label htmlFor="cellphone">
          휴대전화 번호 <span className="required">*</span>
        </label>
        <input
          type="text"
          id="cellphone"
          name="cellphone"
          value={values.cellphone}
          onChange={handleChange}
        />
      </div>
      {touched.cellphone && errors.cellphone && (
        <CommonRequiedMessage>{errors.cellphone}</CommonRequiedMessage>
      )}

      <div className="join-field">
        <label htmlFor="email">
          이메일 <span className="required">*</span>
        </label>
        <input
          id="email"
          type="text"
          name="email"
          value={values.email}
          onChange={handleChange}
        />
      </div>
      {touched.email && errors.email && (
        <CommonRequiedMessage>{errors.email}</CommonRequiedMessage>
      )}

      <CommonButton
        type="submit"
        width={"100%"}
        fontSize={"1.2rem"}
        style={{
          marginBottom: isInitialValues ? "2rem" : "0rem",
        }}
      >
        {isInitialValues ? "저장" : "가입하기"}
      </CommonButton>
    </JoinFormContainer>
  );
}

const JoinFormContainer = styled.form`
  width: 460px;
  margin-top: 1rem;

  #halfJoinField {
    display: flex;
    justify-content: space-between;

    .join-field {
      width: calc(50% - 10px);
    }

    select {
      padding: 0.6rem;
      border-radius: 0.4rem;
      border: 1px solid ${({ theme }) => theme.color.border};
    }
  }

  .join-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    label {
      margin-bottom: 0.4rem;
    }
  }

  #birthInput {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;

    input {
      width: 22%;
    }
  }
`;

export default JoinForm;
