// 상담사 - 상담 리스트 검색

//* hooks
import styled from "styled-components";
import { useState } from "react";

//* style
import { CommonButton } from "@styles/common";

//* component
import CalendarPicker from "@components/common/CalendarPicker";

function ConsultationSearchBar({
  searchData,
  setSearchData,
  consultationListSearch,
}) {
  //* state
  const [startDate, setStartDate] = useState(searchData.start_date);
  const [endDate, setEndDate] = useState(searchData.end_date);
  const [isShowStartCalendar, setIsShowStartCalendar] = useState(false);
  const [isShowEndCalendar, setIsShowEndCalendar] = useState(false);

  return (
    <ConsultationSearchBarContainer>
      <div>
        <label htmlFor="username">상담자</label>
        <input
          type="text"
          id="username"
          onChange={(e) =>
            setSearchData((prev) => ({ ...prev, user_name: e.target.value }))
          }
        />
      </div>
      <div>
        <label htmlFor="way">상담방법</label>
        <select
          id="way"
          value={searchData.way}
          onChange={(e) =>
            setSearchData((prev) => ({
              ...prev,
              way: e.target.value,
            }))
          }
        >
          <option value="">선택</option>
          <option value="방문">방문</option>
          <option value="온라인">온라인</option>
          <option value="녹음(녹화)">녹음(녹화)</option>
        </select>
      </div>
      <div id="dateContainer">
        <label>상담일시</label>
        <input
          readOnly
          value={startDate}
          onFocus={() => setIsShowStartCalendar(true)}
        />
        ~
        <input
          readOnly
          value={endDate}
          onFocus={() => setIsShowEndCalendar(true)}
        />
        {isShowStartCalendar && (
          <div id="datePicker">
            <CalendarPicker
              isFormat={true}
              choicePrevDay={true}
              initialDate={startDate}
              setPickDate={setStartDate}
            />
            <CommonButton
              onClick={() => {
                setIsShowStartCalendar(false);
                setSearchData((prevData) => ({
                  ...prevData,
                  start_date: startDate,
                }));
              }}
            >
              선택
            </CommonButton>
          </div>
        )}
        {isShowEndCalendar && (
          <div id="datePicker">
            <CalendarPicker
              isFormat={true}
              choicePrevDay={true}
              initialDate={endDate}
              setPickDate={setEndDate}
            />
            <CommonButton
              onClick={() => {
                setIsShowEndCalendar(false);
                setSearchData((prevData) => ({
                  ...prevData,
                  end_date: endDate,
                }));
              }}
            >
              선택
            </CommonButton>
          </div>
        )}
      </div>
      <CommonButton width="6rem" onClick={consultationListSearch}>
        검색
      </CommonButton>
    </ConsultationSearchBarContainer>
  );
}

const ConsultationSearchBarContainer = styled.div`
  display: flex;
  padding: 1rem;
  margin-top: 1rem;
  align-items: center;
  border-radius: 0.4rem;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.color.border};

  #dateContainer {
    position: relative;

    #datePicker {
      top: 3rem;
      position: absolute;
    }

    input {
      margin: 0rem 0.6rem;
    }
  }

  label {
    margin-right: 1rem;
  }

  select {
    width: 10rem;
    padding: 0.6rem;
    border-radius: 0.4rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }
`;

export default ConsultationSearchBar;
