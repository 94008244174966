// 상담사 - 상담일지 - 상세

//* hooks
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

//* style
import {
  CommonButton,
  ConsultationAreaWrap,
  CommonConsultingDetailContainer,
} from "@styles/common";

//* api
import { api } from "@api";

//* func
import { func } from "@func";

//* components
import Tab from "@components/common/Tab";
import Hour from "@components/common/Hour";
import CalendarPicker from "@components/common/CalendarPicker";
import ScatterplotChart from "@components/common/ScatterplotChart";

function ConsultationDetailPage({
  seq,
  setIsSave,
  cvChart,
  severityChart,
  consultationDetailData,
}) {
  //* state
  const [pickDate, setPickDate] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [actionDetails, setActionDetails] = useState("");
  const [noticeKeywords, setNoticeKeywords] = useState([]);
  const [hour, setHour] = useState({ hour: "", type: "" });
  const [selectAmHourIdx, setSelectAmHourIdx] = useState(-1);
  const [selectPmHourIdx, setSelectPmHourIdx] = useState(-1);
  const [nextReservation, setNextReservation] = useState("y");
  const [isConsultationFinished, setIsConsultationFinished] = useState(false);

  const sttAreaRef = useRef(null);

  const tabList = ["오늘의 상담", "상담 요청 전체"];

  const navigate = useNavigate();

  useEffect(() => {
    noticeKeywordsRequest();
  }, []);

  useEffect(() => {
    setIsConsultationFinished(consultationDetailData.status === "상담완료");
  }, [consultationDetailData]);

  useEffect(() => {
    if (sttAreaRef.current) {
      sttAreaRef.current.scrollTop = sttAreaRef.current.scrollHeight;
    }
  }, [consultationDetailData.dialog_data]);

  const consultationSave = async () => {
    const counsel_dt = `${pickDate} ${
      hour.type === "pm" ? Number(hour.hour) + 12 : hour.hour
    }:00`;

    if (nextReservation === "y" && !hour.hour) {
      alert("차기 상담일 시간을 선택해 주세요.");
      return;
    } else {
      setIsSave(true);

      const cvChartData = JSON.stringify(cvChart);
      const severityChartData = JSON.stringify(severityChart);

      try {
        const saveConsultation = await api.POST_SAVE_CONSULTATION({
          seq,
          cvChartData,
          actionDetails,
          nextReservation,
          severityChartData,
          counsel_dt: nextReservation === "y" && counsel_dt,
        });
        if (saveConsultation.status === 200) {
          alert("상담이 저장되었습니다.");
          navigate("/consultation-history");
        }
      } catch (err) {
        console.log("상담 수정 err", err);
      }
    }
  };

  const noticeKeywordsRequest = async () => {
    try {
      const noticeKeywordsResponse = await api.POST_NOTICE_KEYWORDS_LIST({});
      noticeKeywordsResponse.list.map((data) => data.keyword);
      setNoticeKeywords(noticeKeywordsResponse.list);
    } catch (err) {
      console.log("알림 키워드 리스트 err", err);
      alert("알림 키워드 리스트 err", err);
    }
  };

  return (
    <>
      {!isConsultationFinished && (
        <Tab
          tabList={tabList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      <CommonConsultingDetailContainer width="16.6%">
        <table style={{ marginBottom: "0rem" }}>
          <thead>
            <tr>
              <th className="key">상담일</th>
              <th>{consultationDetailData.counsel_dt}</th>
              <th className="key">상담방법</th>
              <th>{consultationDetailData.counsel_way}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key">상담자</td>
              <td colSpan="3">{consultationDetailData.client_username}</td>
            </tr>
            <tr>
              <td className="key">상담명</td>
              <td colSpan="3">{consultationDetailData.subject}</td>
            </tr>
            <tr>
              <td className="key">상담 요청 내용</td>
              <td colSpan="3">{consultationDetailData.request_details}</td>
            </tr>
            <tr>
              <td className="key">상담조치</td>
              <td colSpan="3">
                {isConsultationFinished ? (
                  consultationDetailData.action_details
                ) : (
                  <textarea
                    defaultValue={consultationDetailData.action_details}
                    onChange={(e) => setActionDetails(e.target.value)}
                  ></textarea>
                )}
              </td>
            </tr>
            <tr>
              <td className="key">차기 상담일 예약</td>
              <td colSpan="3">
                <div id="reservationContainer">
                  {consultationDetailData.status !== "상담완료" ? (
                    <>
                      <div id="reservationChoice">
                        <div>
                          <p>차기 상담일을 예약 하시겠습니까?</p>
                          <div id="reservationInput">
                            <input
                              id="y"
                              value="y"
                              type="radio"
                              name="reservation"
                              checked={nextReservation === "y"}
                              disabled={isConsultationFinished}
                              onChange={(e) =>
                                setNextReservation(e.target.value)
                              }
                            />
                            <label htmlFor="y">예</label>
                            <input
                              id="n"
                              value="n"
                              type="radio"
                              name="reservation"
                              checked={nextReservation === "n"}
                              disabled={isConsultationFinished}
                              onChange={(e) =>
                                setNextReservation(e.target.value)
                              }
                            />
                            <label htmlFor="n">아니오</label>
                          </div>
                        </div>
                      </div>

                      {nextReservation === "y" && (
                        <div id="reservationDate">
                          <CalendarPicker
                            choicePrevDay={false}
                            setPickDate={setPickDate}
                            isDisabled={isConsultationFinished}
                          />
                          <Hour
                            hour={hour}
                            setHour={setHour}
                            pickDate={pickDate}
                            selectAmHourIdx={selectAmHourIdx}
                            selectPmHourIdx={selectPmHourIdx}
                            setSelectAmHourIdx={setSelectAmHourIdx}
                            setSelectPmHourIdx={setSelectPmHourIdx}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    consultationDetailData.is_next_sch
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {consultationDetailData.status === "상담완료" && (
          <>
            <ConsultationAreaWrap style={{ borderTop: "none" }}>
              <div
                className="consultation-area"
                style={{ width: "100%", borderRight: "none" }}
              >
                <div className="key">상담 영상 및 음성</div>
                <div>
                  <div id="consultationVideo">
                    <video style={{ width: 600, height: 480 }} controls>
                      <source
                        src={`${process.env.REACT_APP_VIEWS_API_URL}videoPlay?seq=${seq}&type=${consultationDetailData.counsel_way}`}
                      />
                    </video>
                  </div>
                </div>
              </div>
            </ConsultationAreaWrap>
            <ConsultationAreaWrap style={{ borderTop: "none" }}>
              <div className="consultation-area">
                <div className="key">내담자 감정 상태 그래프</div>
                <div id="consultationGraph">
                  <div>
                    <ScatterplotChart
                      type="cv"
                      data={JSON.parse(consultationDetailData.cv_chart_data)}
                    />
                    <ScatterplotChart
                      type="severity"
                      data={JSON.parse(
                        consultationDetailData.severity_chart_data
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="consultation-area">
                <div className="key">상담내용</div>
                <div id="consultationContentWrap" ref={sttAreaRef}>
                  <div id="consultationContent">
                    {consultationDetailData.dialog_data.map((data, idx) => (
                      <div key={idx} className="speech-bubble">
                        <p>
                          <span
                            style={{
                              color:
                                data.user_id === "admin"
                                  ? "#3ACA9A"
                                  : "#6495ED",
                            }}
                          >
                            {data.user_id === "admin" ? "상담사" : "내담자"}
                          </span>
                          :
                          {data.user_id === "admin"
                            ? data.dialog
                            : func.noticeTextHandler(
                                data.dialog,
                                noticeKeywords
                              )}
                        </p>
                        <span className="date">{data.request_time}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </ConsultationAreaWrap>
          </>
        )}
      </CommonConsultingDetailContainer>

      <CommonButton
        type="button"
        fontSize={"1rem"}
        onClick={() => {
          consultationDetailData.status === "상담완료"
            ? navigate("/consultation-history")
            : consultationSave();
        }}
        style={{
          left: "50%",
          bottom: "1rem",
          position: "absolute",
          padding: "0.6rem 2rem",
          transform: "translateX(-50%)",
        }}
      >
        {consultationDetailData.status === "상담완료" ? "목록" : "저장"}
      </CommonButton>
    </>
  );
}

export default ConsultationDetailPage;
