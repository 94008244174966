// 공통 Data Table

//* hooks
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DataTable({ columns, type, tableData }) {
  //* state
  const [data, setData] = useState(tableData);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const navigate = useNavigate();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
  });

  const navigateToDetail = (id) => {
    if (type !== "report") {
      navigate(`/consultation/${id}`);
    } else {
      navigate(`/consultation-report/${id}`);
    }
  };

  return (
    <>
      <CommonDataTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() =>
                type !== "noticeKeyword" && navigateToDetail(row.original.seq)
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </CommonDataTable>
      <Pagination>
        <button
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        {table.getPageOptions().map((page) => {
          const isActive = table.getState().pagination.pageIndex === page;
          return (
            <button
              key={page}
              onClick={() => table.setPageIndex(page)}
              className={isActive ? "active" : ""}
            >
              {page + 1}
            </button>
          );
        })}
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        {/* <select
          style={{ margin: "5px" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select> */}
      </Pagination>
    </>
  );
}

const CommonDataTable = styled.table`
  width: 100%;
  margin-top: 2rem;
  background-color: #fff;

  th,
  td {
    padding: 0.8rem 1rem;
    border-right: 1px solid ${({ theme }) => theme.color.border};
    border-bottom: 1px solid ${({ theme }) => theme.color.border};
  }

  th,
  td:last-child {
    border-right: none;
  }

  th {
    background-color: ${({ theme }) => theme.color.secondary};
    border-top: 1px solid ${({ theme }) => theme.color.border};
    border-right: 1px solid ${({ theme }) => theme.color.border};
  }

  th:last-child {
    border-right: none;
  }

  td {
    cursor: pointer;
  }
`;

const Pagination = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;

  button {
    margin: 0 0.2rem;
    padding: 0.4rem 0.6rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }

  .active {
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;

export default DataTable;
